<template>
  <div class="container-fluid px-0">
    <b-card no-body>
      <b-card-header class="d-flex flex-row align-items-center">DAQ Site Setup</b-card-header>
      <b-card-body>
        <b-row>
          <b-col>
            <div class="text-max-width">
              This page lets you create resources for the DAQ system and portal. You can currently create <b>sites</b> and <b>gateways</b>
              from this page, for other DAQ resources such as modules or sensors, please visit the
              <a href="https://beta.daq.morgansolar.xyz" target="">DAQ portal</a>
              after creating a site and create them there.
              <b>You must have a specific permission to create resources from here, contact Talat if this is your first time.</b>
            </div>

            <b-form-group label="Select a resource to create" class="mt-3 input-max-width">
              <b-form-select v-model="resource" :options="resourceOptions" />
            </b-form-group>

            <div>
              <component :is="resource" />
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import SiteForm from './SiteForm.vue';
import GatewayForm from './GatewayForm.vue';

export default {
  name: 'SiteSetup',
  components: {
    SiteForm,
    GatewayForm
  },
  data() {
    return {
      resource: null,
      resourceOptions: [
        { value: null, text: 'Please select an option' },
        { value: 'SiteForm', text: 'Site' },
        { value: 'GatewayForm', text: 'Gateway' },
      ]
    };
  }
};
</script>

<style scoped>
.text-max-width {
  max-width: 1000px;
}

.input-max-width {
  max-width: 400px;
}
</style>
