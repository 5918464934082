<template>
  <div class="form">
    <b-form-group
      class="mt-3"
      :invalid-feedback="v$.code.$errors.length ? v$.code.$errors[0].$message : ''"
      :state="!v$.code.$error">
      <template #label>
        <div class="d-flex align-items-center">
          <div class="required">Code</div>
          <font-awesome-icon
            icon="info-circle"
            class="form-info-icon"
            v-b-tooltip.hover.right="`A unique id for the site. Tip: pick a code that is an acronym of the site or close to it.`" />
        </div>
      </template>
      <b-input-group prepend="D-">
        <b-form-input v-model="v$.code.$model" trim :state="v$.code.$error ? false : null" :disabled="loading" autocomplete="off" />
      </b-input-group>
    </b-form-group>

    <b-form-group
      class="mt-3"
      :invalid-feedback="v$.name.$errors.length ? v$.name.$errors[0].$message : ''"
      :state="!v$.name.$error">
      <template #label>
        <div class="d-flex align-items-center">
          <div class="required">Name</div>
          <font-awesome-icon
            icon="info-circle"
            class="form-info-icon"
            v-b-tooltip.hover.right="`The name of the site`" />
        </div>
      </template>
      <b-form-input v-model="v$.name.$model" trim :state="v$.name.$error ? false : null" :disabled="loading" autocomplete="off" />
    </b-form-group>

    <b-form-group
      class="mt-3"
      :invalid-feedback="v$.timezone.$errors.length ? v$.timezone.$errors[0].$message : ''"
      :state="!v$.timezone.$error">
      <template #label>
        <div class="d-flex align-items-center">
          <div class="required">Timezone</div>
          <font-awesome-icon icon="info-circle" class="form-info-icon" v-b-tooltip.hover.right="`The IANA timezone`" />
        </div>
      </template>
      <b-form-input
        id="timezone-input"
        v-model="v$.timezone.$model"
        trim
        :state="v$.timezone.$error ? false : null"
        :disabled="loading"
        list="timezone-list" />
      <datalist id="timezone-list">
        <option v-for="tz in timezoneOptions" :key="tz">{{ tz }}</option>
      </datalist>
    </b-form-group>

    <div class="d-flex align-items-center mb-2">
      <div class="required">Coordinates</div>
      <font-awesome-icon
        icon="info-circle"
        class="form-info-icon"
        v-b-tooltip.hover.right="`The longitude and latitude of the site or the city if you don't know the exact location.`" />
    </div>

    <b-row class="pl-2">
      <b-col>
        <b-form-group
          :invalid-feedback="v$.longitude.$errors.length ? v$.longitude.$errors[0].$message : ''"
          :state="!v$.longitude.$error">
          <template #label>
            <div class="required">Longitude</div>
          </template>
          <b-form-input v-model="v$.longitude.$model" trim :state="v$.longitude.$error ? false : null" :disabled="loading" autocomplete="off" />
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group
          :invalid-feedback="v$.latitude.$errors.length ? v$.latitude.$errors[0].$message : ''"
          :state="!v$.latitude.$error">
          <template #label>
            <div class="required">Latitude</div>
          </template>
          <b-form-input v-model="v$.latitude.$model" trim :state="v$.latitude.$error ? false : null" :disabled="loading" autocomplete="off" />
        </b-form-group>
      </b-col>
    </b-row>

    <b-form-group
      :invalid-feedback="v$.location.$errors.length ? v$.location.$errors[0].$message : ''"
      :state="!v$.location.$error">
      <template #label>
        <div class="d-flex align-items-center">
          <div class="required">Location</div>
          <font-awesome-icon icon="info-circle" class="form-info-icon" id="tooltip-location" />
          <b-tooltip target="tooltip-location" triggers="hover" placement="right">
            <div>In the format:</div>
            <div><b>City, Province/State, Country</b></div>
          </b-tooltip>
        </div>
      </template>
      <b-form-input v-model="v$.location.$model" trim :state="v$.location.$error ? false : null" :disabled="loading" autocomplete="off" />
    </b-form-group>

    <div class="d-flex align-items-center">
      <b-button variant="primary" @click="createSite" :disabled="v$.$invalid || loading" class="mr-2">Create Site</b-button>
      <msi-spinner :size="32" v-if="loading"></msi-spinner>
    </div>
  </div>
</template>

<script>
/* eslint-disable object-curly-newline */
import useVuelidate from '@vuelidate/core';
import { required, minLength, maxLength, decimal, minValue, maxValue, alphaNum } from '@vuelidate/validators';
import miniToastr from 'mini-toastr';

import MsiSpinner from '../../components/MsiSpinner.vue';

const tzdata = () => import('tzdata');
miniToastr.init();

export default {
  name: 'SiteForm',
  components: {
    MsiSpinner
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      code: '',
      name: '',
      timezone: '',
      longitude: '',
      latitude: '',
      location: '',
      timezoneOptions: [],
      loading: false
    };
  },
  validations() {
    return {
      code: { required, alphaNum, minLength: minLength(3), maxLength: maxLength(5) },
      name: { required, minLength: minLength(1), maxLength: maxLength(45) },
      timezone: { required, minLength: minLength(1), maxLength: maxLength(65) },
      longitude: { required, decimal, minValue: minValue(-180), maxValue: maxValue(180) },
      latitude: { required, decimal, minValue: minValue(-90), maxValue: maxValue(90) },
      location: { required, minLength: minLength(1), maxLength: maxLength(255) }
    };
  },
  methods: {
    async createSite() {
      const { code, name, timezone, longitude, latitude, location } = this;
      const body = {
        code: `D-${code.toUpperCase()}`,
        name,
        timezone,
        coords: { lng: Number.parseFloat(longitude), lat: Number.parseFloat(latitude) },
        location
      };

      try {
        this.loading = true;
        const response = await this.$daqApi.post('/sites', { body }, true);
        if (response.status >= 200 && response.status <= 299) {
          miniToastr.success(response.body.message, 'Success');
          await this.$kauth.getValidToken(true);
        } else {
          miniToastr.error(response.body.message, `Error ${response.status}`);
        }
      } finally {
        this.loading = false;
      }
    }
  },
  async mounted() {
    const { default: { zones } } = await tzdata();
    this.timezoneOptions = Object.keys(zones);
  }
};
</script>

<style scoped>
.form {
  max-width: 400px;
}

#timezone-input::-webkit-calendar-picker-indicator {
  opacity: 100;
  margin-bottom: 4px;
}
</style>
