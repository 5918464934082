<template>
  <div class="form">
    <b-form-group
      class="mt-3"
      :invalid-feedback="v$.site.$errors.length ? v$.site.$errors[0].$message : ''"
      :state="!v$.site.$error">
      <template #label>
        <div class="d-flex align-items-center">
          <div class="required">Site</div>
          <font-awesome-icon
            icon="info-circle"
            class="form-info-icon"
            v-b-tooltip.hover.right="`The site you want to create the gateway under`" />
        </div>
      </template>
        <b-form-select v-model="site" :options="siteOptions" :state="v$.site.$error ? false : null" :disabled="loading" />
    </b-form-group>

    <b-form-group
      class="mt-3"
      :invalid-feedback="v$.code.$errors.length ? v$.code.$errors[0].$message : ''"
      :state="!v$.code.$error">
      <template #label>
        <div class="d-flex align-items-center">
          <div class="required">Code</div>
          <font-awesome-icon
            icon="info-circle"
            class="form-info-icon"
            v-b-tooltip.hover.right="`A 9-digit unique code for the gateway`" />
        </div>
      </template>
      <b-input-group prepend="DGID">
        <b-form-input v-model="v$.code.$model" trim :state="v$.code.$error ? false : null" :disabled="loading" autocomplete="off" />
      </b-input-group>
    </b-form-group>

    <b-form-group
      class="mt-3"
      :invalid-feedback="v$.name.$errors.length ? v$.name.$errors[0].$message : ''"
      :state="!v$.name.$error">
      <template #label>
        <div class="d-flex align-items-center">
          <div class="required">Name</div>
          <font-awesome-icon
            icon="info-circle"
            class="form-info-icon"
            v-b-tooltip.hover.right="`The name of the gateway`" />
        </div>
      </template>
      <b-form-input v-model="v$.name.$model" trim :state="v$.name.$error ? false : null" :disabled="loading" autocomplete="off" />
    </b-form-group>

    <b-form-group
      class="mt-3"
      :invalid-feedback="v$.serialNumber.$errors.length ? v$.serialNumber.$errors[0].$message : ''"
      :state="!v$.serialNumber.$error">
      <template #label>
        <div class="d-flex align-items-center">
          <div>Serial Number</div>
          <font-awesome-icon
            icon="info-circle"
            class="form-info-icon"
            v-b-tooltip.hover.right="`Optional: the serial number assigned to the gateway`" />
        </div>
      </template>
      <b-form-input v-model="v$.serialNumber.$model" trim :state="v$.serialNumber.$error ? false : null" :disabled="loading" autocomplete="off" />
    </b-form-group>

    <div class="d-flex align-items-center">
      <b-button variant="primary" @click="createGateway" :disabled="v$.$invalid || loading" class="mr-2">Create Gateway</b-button>
      <msi-spinner :size="32" v-if="loading"></msi-spinner>
    </div>

    <div class="pt-3" v-if="response">
      Secret: {{ response }}
    </div>
  </div>
</template>

<script>
/* eslint-disable object-curly-newline */
import useVuelidate from '@vuelidate/core';
import { required, minLength, maxLength, helpers } from '@vuelidate/validators';
import miniToastr from 'mini-toastr';

import MsiSpinner from '../../components/MsiSpinner.vue';

miniToastr.init();

const dgid = helpers.withMessage('Value must be a 9-digit code', helpers.regex(/^[0-9]{9}$/));

export default {
  name: 'GatewayForm',
  components: {
    MsiSpinner
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      siteOptions: [{ value: null, text: 'Please select an option' }],
      site: null,
      name: '',
      code: '',
      serialNumber: '',
      loading: false,
      response: null
    };
  },
  validations() {
    return {
      site: { required },
      name: { required, minLength: minLength(1), maxLength: maxLength(255) },
      code: { required, dgid },
      serialNumber: { minLength: minLength(1), maxLength: maxLength(255) }
    };
  },
  methods: {
    async createGateway() {
      const { site, name, code, serialNumber } = this;
      const body = {
        name,
        code: `DGID${code}`,
        serialNumber: serialNumber || null
      };

      try {
        this.loading = true;
        this.response = null;
        const response = await this.$daqApi.post(`/sites/${site}/gateways`, { body }, true);
        if (response.status >= 200 && response.status <= 299) {
          miniToastr.success('The gateway was created successfully.', 'Success');
          this.response = response.body.secret;
        } else {
          miniToastr.error(response.body.message, `Error ${response.status}`);
        }
      } finally {
        this.loading = false;
      }
    }
  },
  async mounted() {
    const sites = await this.$daqApi.get('/sites');
    this.siteOptions = [...this.siteOptions, ...sites.map(s => ({ value: s.code, text: `${s.name} (${s.code})` }))];
  }
};
</script>

<style scoped>
.form {
  max-width: 400px;
}
</style>
